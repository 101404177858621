import React from "react";
import dayjs from "dayjs";
import { SettingContext } from "./Context";

const MyGlobalData = ({ children }) => {
  let globalData = {
    // URL: "localhost",
    // API_URL: "http://localhost:3003",
    CRMID: "2110130020",
    URL: "crm.propertyvision.in",
    API_URL: "https://backend.crm.propertyvision.in",
    variable: {
      DateTime: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    },
    CompanyName: "PropertyVision",
    ClienCode: "202838",
    Version: "2.0.1",
    currScreen: "",
    login: {
      path: React.lazy(() => import("../pages/Auth/Login/Login")),
    },
    module_privilege: "",
    user_data: "",
    data_array: [],
    pages: {
      components: {
        leftsidebar: React.lazy(() =>
          import("../components/Leftsidebar/Leftsidebar")
        ),
      },
      dashboard: React.lazy(() => import("../pages/Dashboard/Dashboard")),
      hrdashboard: React.lazy(() => import("../pages/Dashboard/HRDashboard")),
      changepassword: React.lazy(() =>
        import("../pages/Auth/ChangePassword/ChangePassword")
      ),
      leads: {
        totalleads: React.lazy(() => import("../pages/Leads/TotalLead")),
        addlead: React.lazy(() => import("../pages/Leads/AddLead")),
        viewleaddetails: React.lazy(() =>
          import("../pages/Leads/ViewLeadDetails")
        ),
        quickeditlead: React.lazy(() => import("../pages/Leads/QuickEditLead")),
        editlead: React.lazy(() => import("../pages/Leads/EditLead")),
        allstatus: React.lazy(() => import("../pages/Leads/AllStatus")),
        nonassignlead: React.lazy(() => import("../pages/Leads/NonAssignLead")),
        assignlead: React.lazy(() => import("../pages/Leads/AssignLead")),
        presentlead: React.lazy(() => import("../pages/Leads/PresentLead")),
        leadbystatus: React.lazy(() => import("../pages/Leads/LeadByStatus")),
        leadbysource: React.lazy(() => import("../pages/Leads/LeadBySource")),
        userlead: React.lazy(() => import("../pages/Leads/UserLead")),
        missedlead: React.lazy(() => import("../pages/Leads/MissedLead")),
        assignleadfrom: React.lazy(() =>
          import("../pages/Leads/AssignLeadFrom")
        ),
      },
      user: {
        adduser: React.lazy(() => import("../pages/Users/AddUsers")),
        allusers: React.lazy(() => import("../pages/Users/AllUsers")),
        useranalyst: React.lazy(() => import("../pages/Users/UsersAnalyst")),
      },
      report: {
        callingreport: React.lazy(() =>
          import("../pages/Report/CallingReport")
        ),
        leadreport: React.lazy(() => import("../pages/Report/LeadReport")),
      },
      dynamicfields: {
        source: React.lazy(() => import("../pages/DynamicFields/Source")),
        projectname: React.lazy(() =>
          import("../pages/DynamicFields/ProjectName")
        ),
        configuration: React.lazy(() =>
          import("../pages/DynamicFields/Configuration")
        ),
        leadstatus: React.lazy(() =>
          import("../pages/DynamicFields/LeadStatus")
        ),
        candidatessource: React.lazy(() =>
          import("../pages/DynamicFields/CandidatesSource")
        ),
        candidatestatus: React.lazy(() =>
          import("../pages/DynamicFields/CandidateStatus")
        ),
        candidatepost: React.lazy(() =>
          import("../pages/DynamicFields/CandidatePost")
        ),
      },
      humanresource: {
        addcandidate: React.lazy(() =>
          import("../pages/HumanResource/AddCandidate")
        ),
        allcandidate: React.lazy(() =>
          import("../pages/HumanResource/AllCandidate")
        ),
        quickeditcandidate: React.lazy(() =>
          import("../pages/HumanResource/QuickEditCandidate")
        ),
        editcandidate: React.lazy(() =>
          import("../pages/HumanResource/EditCandidate")
        ),
        assigncandidatefrom: React.lazy(() =>
          import("../pages/HumanResource/AssignCandidateFrom")
        ),
        assigncandidate: React.lazy(() =>
          import("../pages/HumanResource/AssignCandidate")
        ),
        nonassigncandidate: React.lazy(() =>
          import("../pages/HumanResource/NonAssignCandidate")
        ),
        viewcandidate: React.lazy(() =>
          import("../pages/HumanResource/ViewCandidate")
        ),
      },
      loan: {
        addloan: React.lazy(() => import("../pages/Loan/AddLoan")),
        loandetails: React.lazy(() => import("../pages/Loan/LoanDetails")),
        editloan: React.lazy(() => import("../pages/Loan/EditLoan")),
        viewloan: React.lazy(() => import("../pages/Loan/ViewLoan")),
      },
      other: {
        loader: React.lazy(() => import("../pages/Other/Loader")),
        profile: React.lazy(() => import("../pages/Other/Profile/Profile")),
      },
      broker: {
        allbroker: React.lazy(() => import("../pages/Broker/AllBroker")),
      },
    },
  };

  return (
    <SettingContext.Provider value={{ globalData }}>
      {children}
    </SettingContext.Provider>
  );
};

export default MyGlobalData;
